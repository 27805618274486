import Cropper from 'cropperjs';
document.addEventListener("turbolinks:load", function() {
  var image = document.getElementById('image');
  var input = document.getElementById('input');
  var modal = document.getElementById('modal');
  var close = document.getElementById('close');
  var cancel = document.getElementById('cancel');
  var cropper;

  if (input != null) {
    input.addEventListener('change', function (e) {
      var files = e.target.files;
      var done = function (url) {
        input.value = '';
        image.src = url;
        modal.classList.add("is-active");
        cropper = new Cropper(image, {
          aspectRatio: 1,
          viewMode: 0,
        });
      };
      var reader;
      var file;
      var url;

      if (files && files.length > 0) {
        file = files[0];

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = function (e) {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    });

    document.getElementById('crop').addEventListener('click', function () {
      var canvas;

      modal.classList.remove("is-active");

      if (cropper) {
        canvas = cropper.getCroppedCanvas({
          width: 160,
          height: 160,
        });
        canvas.toBlob(function (blob) {
          var url = window.location.href;
          var edit_url = url.replace("/upload_logo", "/edit")
          url = url.replace("/upload_logo", "")
          $.ajax({
            type: 'PUT',
            url: url,
            data: {'company' : { 'logo': canvas.toDataURL() }},
            dataType: "json",
            success: function(data) {
              window.location.assign(edit_url);
            },
          })
        });
      }
    });

    cancel.onclick = function () {
      window.location.reload();
    };
    close.onclick = function () {
      window.location.reload();
    };
  }
});
