$(document).on("turbolinks:load", function() {
  var fixHelper = function(e, ui) {
    ui.children().each(function() {
      $(this).width($(this).width());
    });
    return ui;
  };

  $(".sortable").sortable({
    placeholder: "drop-highlight",
    handle: '.handle',
    helper: fixHelper,
    update: reorder
  });

  window.moveItem = function(direction, id) {
    url = window.location.href;
    url = url+'/reorder_mobile.js';

    $.get(url,{ direction: direction, item_id: id }).fail(function( data ) {
      alert( "An error occured when reordering. Please try again");
    });
  }
});

var reorder = function(e){
  var order = new Array;
  if($('#queue-items-table').length > 0){
    $('#queue-items-table tbody tr').each(function(){
      itemId = $(this).attr('id');
      bits = itemId.split('-');
      order.push(bits[1]);
    });
  }

  //send order through if we have items to send
  if(order.length > 0){
    url = window.location.href;
    url = url+'/reorder';

    $.get(url,{ order: order }).fail(function( data ) {
      alert( "An error occured when reordering. Please try again");
    });
  }
}
